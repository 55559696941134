import React from "react";
import moment from "moment";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "moment/locale/pt-br"; // Importa o locale de português brasileiro

import MainContainer from "../../components/MainContainer";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";

// Configura o moment para usar o locale pt-br
moment.locale("pt-br");

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(BigCalendar);

// Textos em português para o calendário
const messages = {
    next: "Próximo",
    previous: "Anterior",
    today: "Hoje",
    month: "Mês",
    week: "Semana",
    day: "Dia",
    agenda: "Agenda",
    date: "Data",
    time: "Hora",
    event: "Evento",
    noEventsInRange: "Nenhum evento neste intervalo",
    showMore: (total) => `+ Ver mais (${total})`
};

const useStyles = makeStyles((theme) => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    textField: {
        marginRight: theme.spacing(1),
        width: "100%",
    },
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "auto",
    },
    "@global": {
        ".rbc-calendar": {
            fontFamily: "'Nunito', sans-serif !important", // Estilo global
        },
        ".rbc-header": {
            fontWeight: "600 !important",
        },
        ".rbc-event": {
            fontWeight: "400 !important",
        },
        ".rbc-day-slot": {
            fontFamily: "'Nunito', sans-serif !important",
        },
    },
}));

const Calendar = () => {
    const classes = useStyles();

    return (
        <MainContainer>
            <MainHeader>
                <Title>Calendário para Agendamentos</Title>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined">
                <DnDCalendar                    
                    defaultDate={new Date()}
                    defaultView="month"
                    events={[
                        {
                            start: new Date(),
                            end: new Date(moment().add(1, "days")),
                            title: "Some title",
                        },
                    ]}
                    localizer={localizer}
                    messages={messages} // Aplica as mensagens em português
                    onEventDrop={(e) => console.log(e)}
                    resizable
                    style={{ height: "100%" }}
                />
            </Paper>
        </MainContainer>
    );
};

export default Calendar;